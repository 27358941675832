/**
 * Created by jerry on 2020/03/30.
 * 时效卡经手人 api
 */
import * as API from '@/api/index'

export default {
    // 获取时效卡经手人设置列表
    getTimeCardSaleHandler: params => {
        return API.POST('api/timeCardSaleHandler/all', params)
    },
    // 创建时效卡经手人设置
    createTimeCardSaleHandler: params => {
        return API.POST('api/timeCardSaleHandler/create', params)
    },
    // 更新时效卡经手人设置
    updateTimeCardSaleHandler: params => {
        return API.POST('api/timeCardSaleHandler/update', params)
    },
    // 调整时效卡经手人设置顺序
    moveTimeCardSaleHandler: params => {
        return API.POST('api/timeCardSaleHandler/move', params)
    },
}